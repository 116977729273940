import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { MatProgressSpinnerModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EquipmentApiService } from '@xpo-ltl-2.0/sdk-equipment';
import { LocationApiService } from '@xpo-ltl-2.0/sdk-location';

import { CONFIG_MANAGER_SERVICE_CONFIGURATION, ConfigManagerModule } from '@xpo-ltl/config-manager';
import { DataApiService, NotificationService } from '@xpo-ltl/data-api';
import { LoginModule } from '@xpo-ltl/login';
import { XpoLtlAuthConfigLoaderService, XpoLtlAuthModule } from '@xpo-ltl/ngx-auth';
import {
  XpoAccountPopoverModule,
  XpoAppSwitcherPopoverModule,
  XpoButtonModule,
  XpoCardModule,
  XpoContextualHeaderModule,
  XpoDialogModule,
  XpoFeedbackModule,
  XpoPageNotFoundRoutingModule,
  XpoShellModule,
  XpoSnackBarModule,
} from '@xpo-ltl/ngx-ltl-core';
import { SdkAppMetadataModule } from '@xpo-ltl/sdk-appmetadata';
import { SdkInfrastructureModule } from '@xpo-ltl/sdk-infrastructure';
import { SdkLocationModule } from '@xpo-ltl/sdk-location';
import { SdkLoggingModule } from '@xpo-ltl/sdk-logging';
import { SdkUserPreferenceModule } from '@xpo-ltl/sdk-userpreference';
import { SdkYardManagementModule } from '@xpo-ltl/sdk-yardmanagement';
import { XPO_AUTH_CONFIG, XpoAuthConfig } from '@xpo/ngx-auth';
import {
  XpoLtlDialogTitleModule,
  XpoLtlReleaseNotesComponent,
  XpoLtlReleaseNotesModule,
  XpoLtlRoleSwitcherComponent,
  XpoLtlRoleSwitcherModule,
  XpoLtlSicSwitcherModule,
} from '@xpo/ngx-ltl';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SicSwitcherModule } from './components/app-sic-switcher/sic-switcher.module';
import { AppToolbarComponent } from './components/app-toolbar/app-toolbar.component';
import { UpdatedReleaseNotesComponent } from './components/updated-release-notes/updated-release-notes.component';
import { DialogModule } from './dialogs/dialog.module';
import { MaterialModule } from './material.module';
import { AppFeatureService } from './services/app-feature/app-feature.service';
import { DialogService } from './services/dialog.service';
import { ServiceCentersService } from './services/service-centers/service-centers.service';
import { SicSwitchService } from './services/sic-switch/sic-switch.service';
import { YmStoreModule } from './store';
import { EquipmentPipe } from './templates/pipes/equipment.pipe';
// tslint:disable-next-line: max-line-length
import { XpoFooterModule } from './xpo-footer/xpo-footer.module';
import { LocationDialogModule } from './yard-location/dialogs/location.dialog.module';

export function authConfigFactory(configService: XpoLtlAuthConfigLoaderService): XpoAuthConfig {
  configService.initSettings('./assets/config.json');

  return new XpoAuthConfig({
    appCode: configService.appCode,
    scopes: configService.scopes,
    isProd: configService.isProd,
    applicationRootUri: configService.appRootUri,
  });
}

@NgModule({
  declarations: [AppComponent, AppToolbarComponent, UpdatedReleaseNotesComponent, EquipmentPipe],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    BrowserModule,
    ConfigManagerModule,
    LoginModule,
    DialogModule,
    LocationDialogModule,
    BrowserAnimationsModule,
    MaterialModule,
    XpoAccountPopoverModule,
    XpoAppSwitcherPopoverModule,
    XpoLtlReleaseNotesModule,
    XpoLtlSicSwitcherModule,
    XpoLtlRoleSwitcherModule,
    XpoButtonModule,
    XpoCardModule,
    XpoDialogModule,
    XpoShellModule,
    XpoFeedbackModule,
    XpoSnackBarModule,
    SdkAppMetadataModule,
    SdkLocationModule,
    SdkUserPreferenceModule,
    SdkInfrastructureModule,
    SdkLoggingModule,
    SdkYardManagementModule,
    YmStoreModule,
    XpoContextualHeaderModule,
    XpoLtlAuthModule.forRoot({
      provide: XPO_AUTH_CONFIG,
      useFactory: authConfigFactory,
      deps: [XpoLtlAuthConfigLoaderService],
    }),
    AppRoutingModule,
    XpoPageNotFoundRoutingModule,
    XpoFooterModule,
    MatProgressSpinnerModule,
    SicSwitcherModule,
    XpoLtlDialogTitleModule,
  ],
  providers: [
    {
      provide: CONFIG_MANAGER_SERVICE_CONFIGURATION,
      useValue: { configFileUrl: './assets/config.json' },
    },
    AppFeatureService,
    DialogService,
    DataApiService,
    LocationApiService,
    EquipmentApiService,
    NotificationService,
    SicSwitchService,
    AppFeatureService,
    ServiceCentersService,
  ],
  exports: [UpdatedReleaseNotesComponent],
  entryComponents: [XpoLtlReleaseNotesComponent, XpoLtlRoleSwitcherComponent, UpdatedReleaseNotesComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
